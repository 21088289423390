import React from "react";
import HomeSIngleService from "../../../../components/HomeSIngleService/HomeSIngleService";

const ServicesOneABout = () => {
   const services = [
     {
       id: "1",
       img: "img/services/service1.png",
       title: "Chiropractic Therapy",
       text: "Chiropractic is a health care profession. Chiropractors perform adjustments (manipulations) to the spine or other parts of the body. The goal is to correct alignment problems, ease pain, and support the body's natural ability to heal itself.",
     },
     {
       id: "2",
       img: "img/services/service2.png",
       title: "Vertebral Subluxation",
       text: "Vertebral subluxation can be referred to as a partial dislocation of a spinal vertebra from its normal position causing pain, muscle tenderness, inflammation and could eventually result in a decrease of spinal mobility.",
     },
     {
       id: "3",
       img: "img/services/service3.png",
       title: "Radiculopathy & Paraesthesia",
       text: "Paraesthesia is an abnormal sensation, typically tingling or prickling, caused chiefly by pressure on or damage to peripheral nerves. It is commonly referred to as pins and needles.",
     },
     {
       id: "4",
       img: "img/services/service4.png",
       title: "Wellness Care",
       text: "Health as being - a state of complete physical, mental, and social well-being, and not merely the absence of disease or infirmity is the ultimate goal of chiropractic care.",
     },
     {
       id: "5",
       img: "img/services/service4.png",
       title: "Rehabilitation Therapy",
       text: "At Wellness Chiropractic, we offer rehabilitative services in conjunction with spinal adjustments aiming to ease pain, improve range of movement and quality of life and prevent further injury or disability.",
     },
   ];
  return (
    <>
      <section className="about-area pt-120 pb-90">
        <div className="container">
          <div className="row ">
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="section-title section-title-m-0 pos-rel mb-50 text-end">
                <div className="section-icon">
                  <img
                    className="section-back-icon back-icon-right"
                    src="img/section/section-back-icon.png"
                    alt=""
                  />
                </div>
                <div className="section-text section-text-small pos-rel">
                  <h5>health care facility</h5>
                  <h1>
                    Would you rather stay at home than go into a health care
                    facility?
                  </h1>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="facalty-text mb-50">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nost rud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum. Sed ut perspiciatis unde omnis
                  iste natus error sit voluptatem accusantium.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {services.map((service, i) => (
              <HomeSIngleService key={i} service={service} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesOneABout;
