import React from 'react';
import { Link } from 'react-router-dom';
import useGlobalContext from '../../hooks/useGlobalContext';

const HomeHeroSingleSlide = ({ bg_className}) => {
   const videoPopup = useGlobalContext();
   const {setIsOpen} = videoPopup
   return (
     <>
       <div
         className={`single-slider slider-height d-flex align-items-center slider_bg_${bg_className}`}
       >
         <div className="container">
           <div className="row">
             <div className="col-xl-6 col-lg-8 col-md-10">
               <div className="hero-text">
                 <div className="hero-slider-caption ">
                   {/* <h5>We are here for your care.</h5> */}
                   <h2>WELCOME TO WELLNESS CHIROPRACTIC CONSULTANTS</h2>
                   <p>
                     Wellness Chiropractic is aimed at helping you live a more
                     functional and healthy life, we will provide you with
                     premier Chiropractic care.
                   </p>
                 </div>
                 <div className="hero-slider-btn">
                   <Link to="/contact" className="primary_btn btn-icon ml-0">
                     <span>+</span>Make Appointment
                   </Link>

                   <a
                   target='_blank'
                     href="https://www.facebook.com/share/v/FS13cimkchTfZy1c/?mibextid=qi2Omg"
                     className="play-btn popup-video"
                   >
                     <i className="fas fa-play"></i>
                   </a>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </>
   );
};

export default HomeHeroSingleSlide;