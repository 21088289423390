import React from "react";
import HomeSIngleService from "../../../../components/HomeSIngleService/HomeSIngleService";

const HomeServices = () => {
  const services = [
    {
      id: "1",
      img: "img/services/service1.png",
      title: "Chiropractic Therapy",
      text: "Chiropractic is a health care profession. Chiropractors perform adjustments (manipulations) to the spine or other parts of the body. The goal is to correct alignment problems, ease pain, and support the body's natural ability to heal itself.",
    },
    {
      id: "2",
      img: "img/services/service2.png",
      title: "Vertebral Subluxation",
      text: "Vertebral subluxation can be referred to as a partial dislocation of a spinal vertebra from its normal position causing pain, muscle tenderness, inflammation and could eventually result in a decrease of spinal mobility.",
    },
    {
      id: "3",
      img: "img/services/service3.png",
      title: "Radiculopathy & Paraesthesia",
      text: "Paraesthesia is an abnormal sensation, typically tingling or prickling, caused chiefly by pressure on or damage to peripheral nerves. It is commonly referred to as pins and needles.",
    },
    {
      id: "4",
      img: "img/services/service4.png",
      title: "Wellness Care",
      text: "Health as being - a state of complete physical, mental, and social well-being, and not merely the absence of disease or infirmity is the ultimate goal of chiropractic care.",
    },
    {
      id: "5",
      img: "img/services/service5.png",
      title: "Rehabilitation Therapy",
      text: "At Wellness Chiropractic, we offer rehabilitative services in conjunction with spinal adjustments aiming to ease pain, improve range of movement and quality of life and prevent further injury or disability.",
    },
  ];
  return (
    <>
      <section className="servcies-area gray-bg pt-115 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
              <div className="section-title text-center pos-rel mb-75">
                <div className="section-icon">
                  <img
                    className="section-back-icon"
                    src="img/section/section-back-icon.png"
                    alt=""
                  />
                </div>
                <div className="section-text pos-rel home_ser_title">
                  <h5>Services</h5>
                  <h1>Services We Offer</h1>
                </div>
                <div className="section-line pos-rel">
                  <img src="img/shape/section-title-line.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {services.map((service, i) => (
              <HomeSIngleService key={i} service={service} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeServices;
