import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
// import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import image1 from "../../../../img/services/service-details-thumb1.jpg";
import image2 from "../../../../img/section/section-back-icon-sky.png";
import image3 from "../../../../img/shape/section-title-line.png";
import image4 from "../../../../img/testimonials/testi-quato-icon.png";
import image5 from "../../../../img/testimonials/testi-author-icon.png";
import image6 from "../../../../img/services/more-ser-1.png";
import image7 from "../../../../img/services/more-ser-2.png";
import image8 from "../../../../img/services/more-ser-3.png";
import image9 from "../../../../img/services/more-ser-4.png";
import image10 from "../../../../img/services/more-ser-5.png";
import image11 from "../../../../img/services/more-ser-6.png";
import bannerImg from "../../../../img/services/services-banner.png";

const ServicesDetailsArea = () => {
  // const [param, setParam] = useState('');
  const { id } = useParams();
  // console.log('this is the id param =>', param)
  // setParam(id);
  const services = [
    {
      id: "1",
      img: image6,
      title: "Chiropractic Therapy",
      text: `<strong>Chiropractic is a health care profession. Chiropractors perform adjustments (manipulations) to the spine or other parts of the body. The goal is to correct alignment problems, ease pain, and support the body's natural ability to heal itself.</strong>`,
    },
    {
      id: "2",
      img: image7,
      title: "Vertebral Subluxation",
      text: "Vertebral subluxation can be referred to as a partial dislocation of a spinal vertebra from its normal position causing pain, muscle tenderness, inflammation and could eventually result in a decrease of spinal mobility. The causes of vertebral subluxations are numerous including and not limited to bad posture, stress, improper lifting, being in a car accident, or sitting for extended periods of time. Treatment of vertebral subluxation involves the use specific techniques to return the vertebrae into their proper positions or mobilize them so they can move freely by a chiropractor. These techniques are spinal adjustments. During an adjustment, the vertebra is freed from the misaligned position and returned to the proper position in the spinal column. Once performed, the adjustment allows the body to heal and maintain homeostasis.",
    },
    {
      id: "3",
      img: image8,
      title: "Radiculopathy & Paraesthesia",
      text: "Paraesthesia is an abnormal sensation, typically tingling or prickling, caused chiefly by pressure on or damage to peripheral nerves. It is commonly referred to as pins and needles. This abnormal sensation usually occurs when one inadvertently puts pressure on a nerve and is resolved once a change in position removes the pressure from the affected nerve. This type of paraesthesia is temporary and is usually resolved without treatment. Paraesthesia may also be chronic and is a sign of nerve damage which is either radiculopathy or neuropathy. Radiculopathy is a condition in which nerve roots become compressed, irritated, or inflamed. The root of the nerves referred to in the term radiculopathy exit the spine near the spinal joint. Frequently, radiculopathy is caused by some type of decreased function or motion at that spinal joint. When this is corrected through chiropractic adjustments, radicular symptoms often decrease.",
    },
    {
      id: "4",
      img: image9,
      title: "Wellness Care",
      text: "Health as being - a state of complete physical, mental, and social well-being, and not merely the absence of disease or infirmity is the ultimate goal of chiropractic care. Wellness Care in chiropractic focuses on keeping your body functioning properly, and allowing the innate healing properties of your body to function as they are supposed to. Chiropractic wellness care checks to make sure your spine is properly aligned, gently corrects any misalignments before you even begin to feel any pain. We believe at Wellness Chiropractic Consultants that periodic patient chiropractic visits permit the identification of joint dysfunction or subluxations and make corrections with spinal adjustments and other manual procedures. We believe that these treatments will aid in preventing diseases of both neuromusculoskeletal and visceral origin. ",
    },
    {
      id: "5",
      img: image10,
      title: "Rehabilitation Therapy",
      text: "At Wellness Chiropractic, we offer rehabilitative services in conjunction with spinal adjustments aiming to ease pain, improve range of movement and quality of life and prevent further injury or disability. The rehabilitation treatment offers a controlled, therapeutic setting to assist your body recover while you regain strength, relearn skills you lost or find new ways to do activities that might now be challenging.",
    },
  ];
  return (
    <>
      <div className="service-details-area pt-120 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-8">
              <article className="service-details-box">
                <div className="service-details-thumb mb-80">
                  <img className="img" src={image1} alt="" />
                </div>
                <div className="section-title pos-rel mb-45">
                  {/* <div className="section-icon">
                    <img
                      className="section-back-icon back-icon-left"
                      src={image2}
                      alt=""
                    />
                  </div> */}
                  <div className="section-text pos-rel">
                    <h2>{services.find((i) => i.id == id).title}</h2>
                  </div>
                  <div className="section-line pos-rel">
                    <img src={image3} alt="" />
                  </div>
                </div>
                <div className="service-details-text mb-30">
                  <div className="dan"></div>{services.find((i) => i.id == id).text}
                </div>

                {/* <div className="testi-box text-center pos-rel mb-0">
                  <div className="testi-content testi-service-content pos-rel">
                    <div className="testi-quato-icon">
                      <img src={image4} alt="" />
                    </div>
                    <p>
                      Excepteur sint occaecat cupidatat non proident, sunt in
                      culpa qui officia deserunt mollit anim id est laborum. Sed
                      ut perspiciatis unde omnis iste natus error sit
                      voluptatem.
                    </p>
                    <span></span>
                  </div>
                  <div className="testi-author">
                    <h2 className="testi-author-title">
                      Rosalina D. Williamson
                    </h2>
                    <span className="testi-author-desination">
                      founder, uithemes
                    </span>
                  </div>
                  <div className="test-author-icon mb-10">
                    <img src={image5} alt="" />
                  </div>
                </div> */}
              </article>
            </div>
            <div className="col-xl-5 col-lg-4">
              <div className="service-widget mb-50">
                <div className="widget-title-box mb-30">
                  <h3 className="widget-title">More Services</h3>
                </div>
                <div className="more-service-list">
                  <ul>
                    {services.map((service, i) => (
                      <li key={i}>
                        <Link to={`/service-detail/${service.id}`}>
                          <div className="more-service-icon">
                            <img src={service.img} alt="" />
                          </div>
                          <div className="more-service-title">{service.title}</div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="service-widget mb-50 border-0 p-0">
                <div className="banner-widget">
                  <Link to="/contact">
                    <img src={bannerImg} alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesDetailsArea;
