import React from "react";
import { Link } from "react-router-dom";

const DoctorDetailsArea = () => {
  return (
    <>
      <div className="doctor-details-area pt-115 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-8">
              <article className="doctor-details-box">
                <div className="section-title pos-rel mb-25">
                  {/* <div className="section-icon">
                    <img
                      className="section-back-icon back-icon-left"
                      src="img/section/section-back-icon-sky.png"
                      alt=""
                    />
                  </div> */}
                  <div className="section-text pos-rel">
                    <h5 className="green-color text-up-case">
                      introducing my self
                    </h5>
                    <h3>Professional Profile.</h3>
                  </div>
                  <div className="section-line pos-rel">
                    <img src="img/shape/section-title-line.png" alt="" />
                  </div>
                </div>
                <div className="service-details-text mb-40">
                  <p>
                    Dr. Rantiola Olugbenga Ogun is son to parents of Ogun State
                    descent – His father hails from Makun, Sagamu Local
                    Government area and mother from Imo, Obafemi-Owode Local
                    Government Area, both of Ogun State, Nigeria.
                  </p>
                  <p>
                    In 1965, the family relocated to London, England where Dr.
                    Ogun grew up in the London neighborhoods of Camberwell,
                    Dulwich and Peckham from the mid 1960’s to the early ‘70’s.
                    In the ‘70’s, the family relocated again, returning to
                    Lagos, Nigeria where he attended secondary school, College
                    of Technology and 2 years of University in Lagos before
                    travelling out of the country to study medicine in the
                    United States of America.
                  </p>
                  <p>
                    While completing the requirements for medical school, he
                    became aware of chiropractic and put efforts towards
                    becoming a Nigerian pioneer in the field of chiropractic.
                    After 4 years of education in tertiary institutions
                    (Richland College and University of Texas at Dallas in
                    Richardson, Texas) he attained the requirements and
                    qualifications for admission into the highly esteemed Parker
                    College of Chiropractic. After a total of 8 years of
                    challenging education and training, he graduated with the
                    qualification Doctor of Chiropractic.
                  </p>
                  <p>
                    Upon becoming a fully qualified, licensed and registered
                    chiropractor, Dr. Ogun has been in private practice while
                    also engaging in teaching, lecturing and raising awareness
                    of chiropractic. He holds memberships and executive
                    positions in chiropractic professional associations.
                    Additionally, he is licensed and registered to practice in
                    The State of Texas, United States of America and in Nigeria.
                    He involves and engages himself in community and social
                    development projects. In his spare time, he engages himself
                    with reading, swimming and golf. Dr. Ogun is married with
                    children.
                  </p>
                </div>

                <div className="section-title pos-rel mb-25">
                  <div className="section-text pos-rel">
                    <h3>
                      Professional Competences, Objectives and Aspirations
                      (Motivation)
                    </h3>
                  </div>
                  <div className="section-line pos-rel">
                    <img src="img/shape/section-title-line.png" alt="" />
                  </div>
                </div>
                <div className="service-details-text mb-35">
                  <p>
                    Dr. Ogun’s education and training emphasizes the
                    understanding of the human body’s function and interaction
                    with its environment and how to use this knowledge to
                    enhance health and well-being. As a new graduate, he opened
                    an ambulatory chiropractic clinic that catered to primary
                    care contacts in general and neuromusculoskeletal cases in
                    particular.
                  </p>
                </div>

                <div className="service-details-feature fix mb-30">
                  <div className="ser-fea-box f-left">
                    {/* <div className="ser-fea-icon f-left">
                      <img src="img/services/ser-fea-icon-1.png" alt="" />
                    </div> */}
                    <div className="ser-fea-list fix">
                      <h3>His duties included:</h3>
                      <ul>
                        <li>
                          <i className="fas fa-check"></i>Facility management
                          and administration.
                        </li>
                        <li>
                          <i className="fas fa-check"></i>Clinical duties
                          (Doctor-Patient contacts).
                        </li>
                        <li>
                          <i className="fas fa-check"></i>Patient Management.
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className="ser-fea-box f-left me-0">
                    <div className="ser-fea-icon f-left">
                      <img src="img/services/ser-fea-icon-2.png" alt="" />
                    </div>
                    <div className="ser-fea-list fix">
                      <h3>Lifestyle support</h3>
                      <ul>
                        <li>
                          <i className="fas fa-check"></i>Didunt ut labore et
                          dolore magna.
                        </li>
                        <li>
                          <i className="fas fa-check"></i>Aliqua. Ut enim ad
                          minim veniam.
                        </li>
                        <li>
                          <i className="fas fa-check"></i>Quis nostrud
                          exercitation ullamco.
                        </li>
                        <li>
                          <i className="fas fa-check"></i>Laboris nisi ut
                          aliquip ex ea.
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>

                {/* <div className="section-title pos-rel mb-25">
                  <div className="section-text pos-rel">
                    <h1>Care Coverage</h1>
                  </div>
                  <div className="section-line pos-rel">
                    <img src="img/shape/section-title-line.png" alt="" />
                  </div>
                </div> */}
                <div className="service-details-text mb-30">
                  <p>
                    Over the years it became evident to him that there are wide
                    gaps in healthcare delivery that, if filled, would greatly
                    enhance the health and well-being of the general public.
                  </p>
                  <p>
                    The chiropractic philosophy of health and perpetual
                    well-being would go a long way in filling many of these gaps
                    and thus would result in a general improvement of the health
                    of the populace.
                  </p>
                  <p>
                    Realizing this, he is dedicated to making chiropractic
                    doctors, facilities and ancillary services more available to
                    the general public world wide and in Nigeria in particular.
                  </p>
                  <p>
                    This endeavor requires the integration of chiropractic into
                    healthcare delivery systems, both public and private.
                  </p>
                  <p>
                    Thus his motivation is to accomplish this integration by
                    bringing awareness and understanding to both public and
                    private organisations to integrate chiropractic into their
                    healthcare delivery system to enhance the health and
                    well-being of their populace.
                  </p>
                  <p>
                    To accomplish the above stated goals, he continues to
                    acquire more knowledge regarding healthcare management and
                    administration.
                  </p>
                </div>

                {/* <div id="contact-map" className="service-map mb-55">

                           <div style={{ width: '100%' }}>
                              <iframe title="Contact" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58403.623581008454!2d90.32726121307132!3d23.81054442359749!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c0c1c61277db%3A0xc7d18838730e2e59!2z4Kau4Ka_4Kaw4Kaq4KeB4KawLCDgpqLgpr7gppXgpr4!5e0!3m2!1sbn!2sbd!4v1643621605621!5m2!1sbn!2sbd" style={{ width: '100%' }} height={400} allowFullScreen="" loading="lazy"></iframe>
                           </div>

                        </div> */}
              </article>
            </div>
            <div className="col-xl-5 col-lg-4">
              <div className="service-widget mb-50">
                <div className="team-wrapper team-box-2 team-box-3 text-center mb-30">
                  <div className="team-thumb">
                    <img src="img/team/member-big.jpg" alt="" />
                  </div>
                  <div className="team-member-info mt-35 mb-35">
                    <h3>
                      <Link to="/doctorDetails">R. O. Ogun, D.C. (USA)</Link>
                    </h3>
                    <h6 className="f-500 text-up-case letter-spacing pink-color">
                      Dentist & Neurologist (please provide)
                    </h6>
                  </div>
                  <div className="team-social-profile">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-behance"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-pinterest"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* <div className="service-widget mb-50">
                <div className="widget-title-box mb-30">
                  <h3 className="widget-title">Qualifications</h3>
                </div>
                <div className="more-service-list">
                  <ul>
                    <li>
                      <Link to="/doctorDetails">
                        <div className="more-service-icon">
                          <img src="img/services/more-ser-1.png" alt="" />
                        </div>
                        <div className="more-service-title doctor-details-title">
                          Master of Science{" "}
                          <span>Oxford Univercity (2011 - 2014)</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/doctorDetails">
                        <div className="more-service-icon">
                          <img src="img/services/more-ser-5.png" alt="" />
                        </div>
                        <div className="more-service-title doctor-details-title">
                          Neurology Test{" "}
                          <span>Stanford Versity (2015 - 2021)</span>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div> */}

              {/* <div className="service-widget mb-80">
                        <div className="widget-title-box mb-30">
                           <h3 className="widget-title">Get Some Advice?</h3>
                        </div>
                        <form className="service-contact-form" action="">
                           <div className="row">
                              <div className="col-xl-12">
                                 <div className="contact-input contact-icon contact-user mb-20">
                                    <input type="text" placeholder="Enter your name" />
                                 </div>
                              </div>
                              <div className="col-xl-12">
                                 <div className="contact-input contact-icon contact-mail mb-20">
                                    <input type="text" placeholder="Enter your email" />
                                 </div>
                              </div>
                              <div className="col-xl-12">
                                 <div className="contact-input contact-icon contact-hourglass">
                                    <select className="form-select select_style" aria-label="Default select example">
                                       <option defaultValue="Select type of care">Select type of care</option>
                                       <option defaultValue="1">Select type of care</option>
                                       <option defaultValue="2">Select type of care</option>
                                       <option defaultValue="2">Select type of care</option>
                                    </select>
                                 </div>
                              </div>
                           </div>
                        </form>
                        <div className="ser-form-btn text-center mt-40">
                           <a href="#" className="primary_btn btn-icon ml-0"
                              style={{ animationDelay: '0.6s' }} tabIndex="0"><span>+</span>Request for call</a>
                        </div>
                     </div> */}

              {/* <div className="service-widget mb-50">
                <div className="widget-title-box mb-30">
                  <h3 className="widget-title">Languages</h3>
                </div>
                <div className="doctor-detials-lan">
                  <ul>
                    <li>
                      <a href="#">en</a>
                    </li>
                    <li>
                      <a href="#">es</a>
                    </li>
                    <li>
                      <a href="#">bd</a>
                    </li>
                    <li>
                      <a href="#">la</a>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorDetailsArea;
