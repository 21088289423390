import React from 'react';
import HomeTwoSingleMission from '../../../components/HomeTwoSingleMission/HomeTwoSingleMission';

const HomeTwoMissionArea = () => {

   return (
     <>
       <section className="about-area gray-bg pt-115 pb-90">
         <div className="container">
           <div className="row">
             <div className="col-xl-6 col-lg-10 col-md-12">
               <div className="about-title mb-20">
                 <h5 className="pink-color">Our Mission & Vission</h5>
                 <h1>Keep Going With Your Passion.</h1>
               </div>
               <div className="about-text mission-about-text">
                 <p>
                   To serve as many sick people as our capacity permits With
                   great pleasure, to return their health Without the use of
                   dangerous drugs or surgery And to fill their hearts with the
                   knowing That greatness resides inside their body for them To
                   tap into at will. To instill the power of faith And courage
                   in everyone we encounter in practice.
                 </p>
                 <br />
               </div>
               {/* <div className="mission-vision-list pr-90">
                        <HomeTwoSingleMission icon="1" />
                        <HomeTwoSingleMission icon="1" border="border-0" />
                     </div> */}
             </div>
             <div className="col-xl-6 col-lg-6 d-lg-none d-xl-block">
               <div className="mv-right-img pos-rel mb-30">
                 <img src="img/chiropractic-hand.jpg" alt="" />
               </div>
               <div className="testi-quato-icon about-icon-white d-none d-xl-block">
                 <img src="img/about/about-icon-white.png" alt="" />
               </div>
             </div>
           </div>
         </div>
       </section>
     </>
   );
};

export default HomeTwoMissionArea;