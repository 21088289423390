import React from "react";
import { Link } from "react-router-dom";
import HomeTwoSingleAbout from "../../../../components/HomeTwoSingleAbout/HomeTwoSingleAbout";
// import HomeTwoSingleAbout from '../../../../c'

const HomeAboutArea = () => {
  return (
    <>
      {/* <section className="about-area pt-120 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-5 about_left">
                     <div className="medical-icon-brand-2">
                        <img src="img/about/medical-brand-icon-border.png" alt=""/>
                     </div>
                     <div className="about-left-side pos-rel mb-30">
                        <div className="about-front-img">
                           <img src="img/about/about-img.jpg" alt=""/>
                        </div>
                        <div className="about-shape">
                           <img src="img/about/about-shape.png" alt=""/>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-6 col-lg-7">
                     <div className="about-right-side pt-55 mb-30">
                        <div className="about-title mb-20">
                           <h5>About Us</h5>
                           <h1>Short Story About MediDove Clinic.</h1>
                        </div>
                        <div className="about-text">
                           <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                              irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                              cupidatat non proident, sunt in culpa qui officia.</p>
                           <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                              aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                              occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><br/>
                        </div>
                        <div className="about-author d-flex align-items-center">
                           <div className="author-ava">
                              <img src="img/about/author-ava.png" alt=""/>
                           </div>
                           <div className="author-desination">
                              <h4>Rosalina D. Williamson</h4>
                              <h6>founder</h6>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section> */}

      <section className="about-area about-area-mid pt-120 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 col-lg-12 col-md-11">
              <div className="about-right-side pt-25 mb-30">
                <div className="about-title mb-20">
                  <h5 className="pink-color text-center">About Us</h5>
                  {/* <h4>What Is Chiropractic?</h4> */}
                </div>
                <div className="about-text">
                  <p>
                    Wellness Chiropractic Consultants Limited has based its
                    vision and mission on the long-term sustenance of wellness,
                    using chiropractic care as a complementary service to
                    mainstream healthcare. Registered with the Nigeria Company
                    Incorporation in 2009, with RC Number 839225, the company
                    operates in Abuja and has been providing excellent service
                    to patients from different walks of life, requiring its
                    expertise with resounding success.
                  </p>

                  <p>
                    The undersigned, who is the principal consultant, received
                    his formal education as a chiropractor from Parker
                    University College of Chiropractic in the United States of
                    America. Wellness Chiropractic Consultants is currently the
                    only company providing chiropractic services in Abuja.
                    Wellness Chiropractic Consultants Limited is expanding its
                    operations by adding several new clinics in addition to the
                    one already operating in Abuja. The preferred locations for
                    the new clinics include Lagos, Kaduna, Port Harcourt, Benin
                    City, and Kano. Subsequently, any other qualifying Nigerian
                    city will be considered.
                  </p>

                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-12 col-md-12">
              <div className="row">
                <HomeTwoSingleAbout
                  icon="1"
                  title="Advanced Care"
                  spacing="b-40"
                />
                <HomeTwoSingleAbout
                  icon="2"
                  title="Respite Care"
                  spacing="t-40"
                />
                <HomeTwoSingleAbout
                  icon="3"
                  title="Daily Care"
                  spacing="b-30"
                />
                <HomeTwoSingleAbout
                  icon="4"
                  title="Neuorology Care"
                  spacing="t-40 mb-30"
                />
              </div>
            </div>

            <div className="col-xl-6 col-lg-12 col-md-11">
              <div className="about-right-side pt-25 mb-30">
                <div className="about-title mb-20">
                  <h5 className="pink-color">About Us</h5>
                  <h4>What Is Chiropractic?</h4>
                </div>
                <div className="about-text">
                  <p>
                    Chiropractic which derives its name from two Greek words,
                    Chiros (Hand) and Praktikos (To do/be efficient) meaning one
                    who is efficient in healing with their hands without drugs
                    and surgery. Chiropractic is a healing art, science and
                    philosophy founded on the self-evident truth that: “The
                    power that made and maintains the body heals the body”. A
                    health care discipline which emphasizes the inherent
                    recuperative power of the body to heal itself without the
                    use of drugs or surgery. It focuses on the relationship
                    between structure (primarily the spine) and function (as
                    coordinated by the nervous system) and how that relationship
                    affects the preservation and restoration of health. As
                    stated by DD Palmer, the founder of the chiropractic
                    profession, “Functions performed in a normal manner and
                    amount result in health. Diseases are conditions resulting
                    from either an excess or deficiency of functioning.”
                  </p>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-12 col-md-11">
              <div className="about-right-side pt-25 mb-30">
                <div className="about-title mb-20">
                  {/* <h5 className="pink-color">About Us</h5> */}
                  <h4>The Science</h4>
                </div>
                <div className="about-text">
                  <p>
                    The Science: Chiropractic is based on the scientific and
                    anatomical fact that the central nervous system (CNS) that
                    includes the brain and spinal cord, initiate control of the
                    function of every cell, tissue, organ and system of the
                    body. The body is much like the Internet: the brain is the
                    main frame computer, the spinal cord is the information
                    highway, the spinal vertebrae are the protective housing in
                    which the CNS sits. The skull and spine protects the CNS and
                    the spinal cord which is really just a long extension of the
                    brain. Emerging from the spinal cord, between the vertebrae
                    are the 32 pairs of spinal nerves that permeate the entire
                    body. These peripheral nerves control sensory, motor and
                    sympathetic functions like keeping the heart and lungs
                    working even when asleep. The sympathetic system connects to
                    all the arteries, veins and organs in your body. The
                    disruption of the function of these tissues or organs and
                    the nerve control is called VSC or Vertebral Subluxation
                    Complex. Chiropractic is the science of finding these areas
                    of spinal malfunction, the art of correcting them and the
                    philosophy of all things natural as we have been created.
                  </p>
                  <br />
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-12 col-md-12">
              <div className="row">
                <HomeTwoSingleAbout
                  icon="1"
                  title="Advanced Care"
                  spacing="b-40"
                />
                <HomeTwoSingleAbout
                  icon="2"
                  title="Respite Care"
                  spacing="t-40"
                />
                <HomeTwoSingleAbout
                  icon="3"
                  title="Daily Care"
                  spacing="b-30"
                />
                <HomeTwoSingleAbout
                  icon="4"
                  title="Neuorology Care"
                  spacing="t-40 mb-30"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-12 col-md-12">
              <div className="row">
                <HomeTwoSingleAbout
                  icon="1"
                  title="Advanced Care"
                  spacing="b-40"
                />
                <HomeTwoSingleAbout
                  icon="2"
                  title="Respite Care"
                  spacing="t-40"
                />
                <HomeTwoSingleAbout
                  icon="3"
                  title="Daily Care"
                  spacing="b-30"
                />
                <HomeTwoSingleAbout
                  icon="4"
                  title="Neuorology Care"
                  spacing="t-40 mb-30"
                />
              </div>
            </div>

            <div className="col-xl-6 col-lg-12 col-md-11">
              <div className="about-right-side pt-25 mb-30">
                <div className="about-title mb-20">
                  {/* <h5 className="pink-color">About Us</h5> */}
                  <h4>The Art</h4>
                </div>
                <div className="about-text">
                  <p>
                    Chiropractors master the adjusting "art" of chiropractic
                    which includes a comprehensive evaluation, a review of
                    health history, and arrival at a clear diagnosis and a
                    recommended specific treatment plan customized for each
                    patient. This includes specific spinal adjustments.
                    Adjustment describes the many ways and techniques of
                    directed and controlled pressure to restore spinal segments
                    to a more normal position and range of motion, aiding in
                    increased flexibility, increased immunological function and
                    increased life energy. The Chiropractic adjustment is truly
                    an artistic expression.
                  </p>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-12 col-md-11">
              <div className="about-right-side pt-25 mb-30">
                <div className="about-title mb-20">
                  {/* <h5 className="pink-color">About Us</h5> */}
                  <h4>The Science</h4>
                </div>
                <div className="about-text">
                  <p>
                    Chiropractic philosophy recognizes that the power that
                    created the body can heal the body - as long as there is no
                    interference. The doctor does not heal you; he/she is a
                    co-facilitator with you for your body to be put in the
                    correct environment to heal itself. The main purpose of the
                    chiropractor is to reduce interference to your inborn,
                    innate healing ability. The keystone of Chiropractic
                    philosophy thus, is prevention. Chiropractic philosophy
                    tells us that a healthy, holistically maintained body and
                    lifestyle, free of subluxations will enjoy a long and
                    prosperous life. We are also of the belief that much of the
                    medications and surgeries used in "modern" health care are
                    unnecessary and often harmful. While Chiropractors are not
                    against surgeries and medications, we just feel they are
                    overused on some occasions and that lifestyle habits are,
                    generally speaking, a more important standard to monitor and
                    maintain a healthy body. Thus, the "holistic" lifestyle is
                    that of looking at the "whole" picture of person's life and
                    lifestyle and what patients do; food, nutrition, stress,
                    sleep, exercise, family, relaxation and finding a balance.
                    Again, Chiropractic is not oblivious to the cancers, disease
                    processes, accidents and such that can happen to anyone
                    regardless of lifestyle and that would likely need some
                    medical intervention. However, our essential philosophy is
                    that of less drugs, less surgery and more holistic
                    thinking/lifestyle which by definition would include good
                    nutrition, no smoking, staying out of excess radiation
                    exposure etc. Proper maintenance to enhance performance.
                  </p>
                  <br />
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-12 col-md-12">
              <div className="row">
                <HomeTwoSingleAbout
                  icon="1"
                  title="Advanced Care"
                  spacing="b-40"
                />
                <HomeTwoSingleAbout
                  icon="2"
                  title="Respite Care"
                  spacing="t-40"
                />
                <HomeTwoSingleAbout
                  icon="3"
                  title="Daily Care"
                  spacing="b-30"
                />
                <HomeTwoSingleAbout
                  icon="4"
                  title="Neuorology Care"
                  spacing="t-40 mb-30"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeAboutArea;
